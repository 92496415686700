/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://zpbvn4zkgjdrnb7xm2xolslxma.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:e0dc029c-fc99-4079-900e-c1a5f0a9bfa3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fv09rJ7aK",
    "aws_user_pools_web_client_id": "39s7dfmvtso0306r9appsnqapc",
    "oauth": {
        "domain": "auth.grupobimbo-focustalent.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.grupobimbo-talentoverview.com/",
        "redirectSignOut": "https://www.grupobimbo-talentoverview.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "focustalentprodenv-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d18gaayhf3maah.cloudfront.net",
    "aws_user_files_s3_bucket": "focus-bmb-v2-amplify04951-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
